import { render, staticRenderFns } from "./SectionPublicoMeta.vue?vue&type=template&id=4318262c&scoped=true&"
import script from "./SectionPublicoMeta.vue?vue&type=script&lang=js&"
export * from "./SectionPublicoMeta.vue?vue&type=script&lang=js&"
import style0 from "./SectionPublicoMeta.vue?vue&type=style&index=0&id=4318262c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4318262c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardTitle,VDataTable,VDivider,VIcon})
