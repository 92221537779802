<template>
  <div>
    <BaseTable
      v-if="items.length"
      :headers="headers"
      :items="items"
      :itemsPerPage="items.length"
      :paginate="false"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.individual`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="vincularColaborador(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="green">mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Vincular Colaborador<br />a Campanha</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="listarColaborador(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-account-search</v-icon>
            </v-btn>
          </template>
          <span>Visualizar Colaboradores</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.progressiva`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="vincularColaboradorProgressiva(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="green">mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Faixa</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="listarColaboradorProgressiva(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-account-search</v-icon>
            </v-btn>
          </template>
          <span>Visualizar Faixas</span>
        </v-tooltip>
      </template>
    </BaseTable>

    <DraggableColaboradorCampanhaModelo
      :dialog="dialog"
      :campanha="campanha"
      :colaboradoresNaoParticipantes="colaboradoresNaoParticipantes"
      :colaboradoresParticipantes="colaboradoresParticipantes"
      @close="closeModal"
      @atualizaColaboradoresParticipantes="atualizaColaboradoresParticipantes"
      @atualizaColaboradoresNaoParticipantes="
        atualizaColaboradoresNaoParticipantes
      "
    />

    <DraggableColaboradorCampanhaModeloProgressiva
      :dialog="dialogProgressivo"
      :campanha="campanha"
      :colaboradoresNaoParticipantesProgressiva="
        colaboradoresNaoParticipantesProgressiva
      "
      :colaboradoresParticipantesProgressiva="
        colaboradoresParticipantesProgressiva
      "
      @close="closeModalProgressiva"
      @atualizaColaboradoresParticipantesProgressiva="
        atualizaColaboradoresParticipantesProgressiva
      "
    />

    <ModalColaboradorCampanhaModelo
      :items="colaboradoresParticipantes"
      :dialog="dialogList"
      @close="dialogList = false"
    />

    <ModalColaboradorCampanhaModeloProgressiva
      :items="colaboradoresParticipantesProgressiva"
      :dialog="dialogListProgressiva"
      @close="dialogListProgressiva = false"
    />
  </div>
</template>
<script>
import parametroService from "@/services/parametros";
import serviceColaborador from "@/services/colaborador";
import serviceCampanha from "@/services/campanha";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import DraggableColaboradorCampanhaModelo from "./DraggableColaboradorCampanhaModelo";
import DraggableColaboradorCampanhaModeloProgressiva from "./DraggableColaboradorCampanhaModeloProgressiva";
import ModalColaboradorCampanhaModelo from "./ModalColaboradorCampanhaModelo";
import ModalColaboradorCampanhaModeloProgressiva from "./ModalColaboradorCampanhaModeloProgressiva";

export default {
  name: "ListaModelosParticipantes",

  components: {
    BaseTable,
    DraggableColaboradorCampanhaModelo,
    DraggableColaboradorCampanhaModeloProgressiva,
    ModalColaboradorCampanhaModelo,
    ModalColaboradorCampanhaModeloProgressiva
  },

  props: {
    items: {
      required: true,
      type: Array
    }
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "Modelo", value: "descricao_modelo" },
        { text: "Individual", value: "individual", align: "center" },
        { text: "Progressiva", value: "progressiva", align: "center" }
      ],

      empresasPerticipantes: "",
      dialog: false,
      dialogProgressivo: false,
      dialogList: false,
      dialogListProgressiva: false,
      campanha: {},
      colaboradoresParticipantes: [],
      colaboradoresNaoParticipantes: [],
      colaboradoresParticipantesProgressiva: [],
      colaboradoresNaoParticipantesProgressiva: []
    };
  },

  mounted() {
    this.getEmpresasPerticipantes();
  },

  watch: {
    items(value) {
      return value;
    }
  },

  methods: {
    async getEmpresasPerticipantes() {
      let {
        data: { data }
      } = await serviceCampanha.getEmpresasParticipantes({
        id_campanha: this.$route.params.idCampanha
      });

      let idEmpresas = [];

      data.map(item => {
        if (item.id_empresa) {
          idEmpresas.push(item.id_empresa);
        }
      });

      this.empresasPerticipantes = idEmpresas.toString();
    },

    async listarColaborador({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_produto
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await parametroService.getMetaModeloColaborador({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_produto
        });

        this.colaboradoresParticipantes = colaboradores.map(colaborador => ({
          ...colaborador
        }));
        this.dialogList = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async listarColaboradorProgressiva({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_produto
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await parametroService.getMetaModeloColaboradorProgressiva({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_produto
        });

        this.colaboradoresParticipantesProgressiva = colaboradores.map(
          colaborador => ({
            ...colaborador
          })
        );
        this.dialogListProgressiva = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async vincularColaborador(modeloCampanha) {
      this.campanha = { ...modeloCampanha };

      try {
        const participantes = await parametroService.getMetaModeloColaborador({
          id_indicador: modeloCampanha.id_indicador,
          id_campanha: modeloCampanha.id_campanha,
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          id_produto: modeloCampanha.id_produto,
          per_page: -1
        });

        const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          ativo: "S",
          per_page: -1
        });

        this.colaboradoresParticipantes = participantes["data"].data;

        const ids = this.colaboradoresParticipantes.map(
          item => item.id_colaborador
        );

        this.colaboradoresNaoParticipantes = colaboradores["data"].data.filter(
          item => !ids.includes(item.id_colaborador)
        );

        this.dialog = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async vincularColaboradorProgressiva(modeloCampanha) {
      this.campanha = { ...modeloCampanha };

      try {
        const participantes = await parametroService.getMetaModeloColaboradorProgressiva(
          {
            id_indicador: modeloCampanha.id_indicador,
            id_campanha: modeloCampanha.id_campanha,
            id_empresa: this.empresasPerticipantes,
            id_funcao: modeloCampanha.id_funcao,
            id_produto: modeloCampanha.id_produto,
            per_page: -1
          }
        );

        const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          ativo: "S",
          per_page: -1
        });

        this.colaboradoresParticipantesProgressiva = participantes["data"].data;

        this.colaboradoresNaoParticipantesProgressiva =
          colaboradores["data"].data;

        this.dialogProgressivo = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async atualizaColaboradoresParticipantesProgressiva() {
      const {
        data
      } = await parametroService.getMetaModeloColaboradorProgressiva({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });
      this.colaboradoresParticipantesProgressiva = data.data;
    },

    async atualizaColaboradoresParticipantes() {
      const { data } = await parametroService.getMetaModeloColaborador({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });
      this.colaboradoresParticipantes = data.data;
    },

    async atualizaColaboradoresNaoParticipantes() {
      const participantes = await parametroService.getMetaModeloColaborador({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });

      const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        ativo: "S",
        per_page: -1
      });

      const ids = participantes.data.data.map(item => item.id_colaborador);

      this.colaboradoresNaoParticipantes = colaboradores["data"].data.filter(
        item => !ids.includes(item.id_colaborador)
      );
    },

    closeModal() {
      this.dialog = false;
      this.colaboradoresParticipantes = [];
      this.colaboradoresNaoParticipantes = [];
    },

    closeModalProgressiva() {
      this.dialogProgressivo = false;
      this.colaboradoresParticipantesProgressiva = [];
      this.colaboradoresNaoParticipantesProgressiva = [];
    }
  }
};
</script>
