<template>
  <div>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
    <v-simple-table>
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th>Indicador</th>
            <th class="text-center">Tipo Meta</th>
            <th class="text-center">Nº Negócios</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id_meta_funcao">
            <td>{{ `${item.id_indicador} - ${item.indicador}` }}</td>
            <td class="text-center">{{ item.tipo_meta | TitleCase }}</td>
            <td class="text-center">
              {{ item.n_negocios }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
  
<script>
import comissao from "@/services/http/comissaoService";
export default {
  props: {
    idFuncao: {
      type: Number,
    }
  },

  data() {
    return {
      items: [],
      loading: false
    };
  },

  methods: {
    async fetchMetaFuncao(idFuncao) {
      try {
        this.loading = true;
        let {data: { data } } = await comissao()
          .metaFuncao()
          .show({ per_page: -1, id_funcao: idFuncao });
        this.items = data;        
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.fetchMetaFuncao(this.idFuncao);
  },

};
</script>

<style></style>
  