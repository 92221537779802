<template>
  <div>
    <v-simple-table class="mb-3 mt-2">
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th class="text-left">Equipes</th>
            <th class="text-left">Colaboradores</th>
            <th class="text-left" v-if="items[0].id_tipo_meta == 6">
              Individual
            </th>
            <th class="text-left" v-else>Progressiva</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.nome_equipe }}</td>
            <td class="text-left">{{ item.total_colaboradores }}</td>
            <td v-if="items[0].id_tipo_meta == 6">
              <v-tooltip top v-if="onEdite">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    @click="vincularColaborador(item)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="green">mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>Vincular Colaborador<br />a Campanha</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    @click="listarColaborador(item)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-account-search</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Colaboradores</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip top v-if="onEdite">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    @click="vincularColaboradorProgressiva(item)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="green">mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Faixa</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    @click="listarColaboradorProgressiva(item)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-account-search</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Faixas</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <DraggableColaboradorCampanha
      :dialog="dialog"
      @close="closeModal"
      @change:fecthEquipes="vincularColaborador($event)"
      :dadosCampanha="campanha"
      :colaboradoresNaoParticipantes="colaboradoresNaoParticipantes"
      :colaboradoresParticipantes="colaboradoresParticipantes"
    />

    <ModalColaboradoresCampanha
      :items="colaboradoresParticipantes"
      :dialog="dialogList"
      @close="dialogList = false"
    />

    <DraggableColaboradorCampanhaProgressiva
      :dialog="dialogProgressivo"
      :campanha="campanha"
      :colaboradoresNaoParticipantesProgressiva="
        colaboradoresNaoParticipantesProgressiva
      "
      :colaboradoresParticipantesProgressiva="
        colaboradoresParticipantesProgressiva
      "
      @close="closeModalProgressiva"
      @atualizaColaboradoresParticipantesProgressiva="
        atualizaColaboradoresParticipantesProgressiva
      "
    />

    <ModalColaboradorCampanhaProgressiva
      :items="colaboradoresParticipantesProgressiva"
      :dialog="dialogListProgressiva"
      @close="dialogListProgressiva = false"
    />
  </div>
</template>
<script>
import campanhaService from "@/services/campanha";
import serviceColaborador from "@/services/colaborador";
import parametroService from "@/services/parametros";

import DraggableColaboradorCampanha from "@/components/campanha/DraggableColaboradorCampanha";
import ModalColaboradoresCampanha from "@/components/campanha/ModalColaboradoresCampanha";
import DraggableColaboradorCampanhaProgressiva from "./DraggableColaboradorCampanhaProgressiva";
import ModalColaboradorCampanhaProgressiva from "./ModalColaboradorCampanhaProgressiva";

export default {
  name: "lista-faixas-progressivas",

  components: {
    DraggableColaboradorCampanha,
    ModalColaboradoresCampanha,
    DraggableColaboradorCampanhaProgressiva,
    ModalColaboradorCampanhaProgressiva
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    onEdite: {
      type: Boolean,
      default: true
    },
    showAdd: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      dialog: false,
      dialogProgressivo: false,
      dialogList: false,
      dialogListProgressiva: false,
      campanha: {},
      colaboradoresParticipantes: [],
      colaboradoresNaoParticipantes: [],
      colaboradoresParticipantesProgressiva: [],
      colaboradoresNaoParticipantesProgressiva: []
    };
  },

  methods: {
    async listarColaborador({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_campanha_equipe,
      indicador
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await campanhaService.getColaboradoresCampanha({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_campanha_equipe
        });

        this.colaboradoresParticipantes = colaboradores.map(colaborador => ({
          ...colaborador,
          indicador
        }));
        this.dialogList = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async listarColaboradorProgressiva({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_campanha_equipe
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await parametroService.getColaboradorCampanhaProgressiva({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_campanha_equipe,
          per_page: -1
        });

        this.colaboradoresParticipantesProgressiva = colaboradores.map(
          colaborador => ({
            ...colaborador
          })
        );
        this.dialogListProgressiva = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async vincularColaboradorProgressiva(campanhaEquipe) {
      this.campanha = { ...campanhaEquipe };
      try {
        const participantes = await parametroService.getColaboradorCampanhaProgressiva(
          {
            id_indicador: campanhaEquipe.id_indicador,
            id_campanha: campanhaEquipe.id_campanha,
            id_empresa: campanhaEquipe.id_empresa,
            id_funcao: campanhaEquipe.id_funcao,
            id_campanha_equipe: campanhaEquipe.id_campanha_equipe,
            per_page: -1
          }
        );

        const colaboradores = await serviceColaborador.getColaboradoresEquipe({
          id_empresa: campanhaEquipe.id_empresa,
          id_funcao: campanhaEquipe.id_funcao,
          id_equipe: campanhaEquipe.id_equipe,
          per_page: -1
        });

        this.colaboradoresParticipantesProgressiva = participantes["data"].data;

        this.colaboradoresNaoParticipantesProgressiva =
          colaboradores["data"].data;

        this.dialogProgressivo = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async atualizaColaboradoresParticipantesProgressiva() {
      const { data } = await parametroService.getColaboradorCampanhaProgressiva(
        {
          id_indicador: this.campanha.id_indicador,
          id_campanha: this.campanha.id_campanha,
          id_empresa: this.campanha.id_empresa,
          id_funcao: this.campanha.id_funcao,
          id_equipe: this.campanha.id_equipe,
          id_campanha_equipe: this.campanha.id_campanha_equipe,
          per_page: -1
        }
      );
      this.colaboradoresParticipantesProgressiva = data.data;
    },

    async vincularColaborador({
      id_empresa,
      id_indicador,
      id_campanha,
      nome_equipe,
      id_funcao,
      cod_funcao,
      id_campanha_equipe,
      id_equipe
    }) {
      this.campanha = {
        id_empresa,
        cod_funcao,
        id_indicador,
        id_campanha,
        nome_equipe,
        id_funcao,
        id_campanha_equipe,
        id_equipe
      };

      let participantes = campanhaService.getColaboradoresCampanha({
        id_indicador,
        id_campanha,
        id_empresa,
        id_funcao,
        id_equipe,
        id_campanha_equipe,
        per_page: -1
      });

      let colaboradores = serviceColaborador.getColaboradoresEquipe({
        id_empresa,
        id_funcao,
        id_equipe,
        per_page: -1
      });

      try {
        const [
          participantesCampanha,
          colaboradoresCampanha
        ] = await Promise.all([participantes, colaboradores]);

        this.colaboradoresParticipantes = participantesCampanha["data"].data;

        const ids = this.colaboradoresParticipantes.map(
          item => item.id_colaborador
        );

        this.colaboradoresNaoParticipantes = colaboradoresCampanha[
          "data"
        ].data.filter(item => !ids.includes(item.id_colaborador));

        this.dialog = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    closeModal() {
      this.dialog = false;
    },

    closeModalProgressiva() {
      this.dialogProgressivo = false;
      this.colaboradoresParticipantesProgressiva = [];
      this.colaboradoresNaoParticipantesProgressiva = [];
    }
  }
};
</script>
