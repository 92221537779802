<template>
  <v-dialog v-model="dialog" max-width="60%" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Colaborador na Equipe -
          <strong>{{ campanha.nome_equipe }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Colaboradores
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodasFaixas"
              :disabled="colaboradoresNaoParticipantesProgressiva.length == 0"
            >
              Adicionar todos
            </v-btn>

            <v-text-field
              v-model="searchNotLink"
              prepend-inner-icon="mdi-magnify"
              label="Buscar não vinculados"
              @input="unLinkedFilter(searchNotLink)"
            >
            </v-text-field>

            <draggable
              handle=".handle"
              class="draggable-area exclude-persons"
              :list="unLinked"
              group="colaboradores"
              @change="moveAdded"
              height="100px"
            >
              <div
                class="v-list-item__content list"
                v-for="(colaborador, index) in unLinked"
                :key="index"
              >
                <div class="list-colaborador-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>
                      {{ colaborador.nome || colaborador.colaborador }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn
                      icon
                      @click="addFaixa(colaborador)"
                      :disabled="!formIsValid"
                    >
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Colaboradores
              Com Faixa
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodasFaixas"
              :disabled="colaboradoresParticipantesProgressiva.length == 0"
            >
              Remover todos
            </v-btn>

            <v-text-field
              v-model="searchLink"
              prepend-inner-icon="mdi-magnify"
              label="Buscar vinculados"
              @input="linkedFilter(searchLink)"
            >
            </v-text-field>

            <draggable
              handle=".handle"
              class="draggable-area include-persons"
              :list="linked"
              group="colaboradores"
              @change="moveRemove"
            >
              <div
                class="v-list-item__content"
                v-for="(colaborador, index) in linked"
                :key="index"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn class="mr-4" icon @click="removeFaixa(colaborador)">
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>

                      {{ colaborador.colaborador }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                    De: {{ colaborador.qtde_de }} Até:
                    {{ colaborador.qtde_ate }} Bônus:
                    {{ colaborador.valor_bonus }}
                  </div>
                </div>
              </div>
            </draggable>
            <v-divider class="mt-5 mb-5"></v-divider>

            <v-row dense align="center">
              <v-col cols="12">
                <FormMetaProgressivaModelo
                  @formIsValid="formIsValid = $event"
                  @form="form = $event"
                  :resetForm="resetForm"
                  @changeResetForm="resetForm = false"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import FormMetaProgressivaModelo from "./modelo/FormMetaProgressivaModelo";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import serviceParametro from "@/services/parametros";

export default {
  name: "DraggableColaboradorCampanhaProgressiva",

  components: {
    draggable,
    BaseSegmento,
    FormMetaProgressivaModelo
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    campanha: {
      type: Object
    },

    colaboradoresNaoParticipantesProgressiva: {
      type: Array
    },

    colaboradoresParticipantesProgressiva: {
      type: Array
    }
  },

  data() {
    return {
      formIsValid: false,
      form: {},
      searchNotLink: "",
      searchLink: "",
      linked: this.colaboradoresParticipantesProgressiva,
      unLinked: this.colaboradoresNaoParticipantesProgressiva,
      resetForm: false
    };
  },

  watch: {
    colaboradoresParticipantesProgressiva(val) {
      this.linked = val;
    },

    colaboradoresNaoParticipantesProgressiva(val) {
      this.unLinked = val;
    }
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha"
    }),

    optionsVuetifyMoney() {
      return {
        locale: "pt-BR",
        prefix: "R$",
        length: 3,
        precision: 2
      };
    }
  },

  methods: {
    async addTodasFaixas() {
      try {
        await Promise.all(
          this.colaboradoresNaoParticipantesProgressiva.map(async item => {
            const form = {
              id_campanha: this.$route.params.idCampanha,
              id_empresa: item.id_empresa,
              id_campanha_equipe: this.campanha.id_campanha_equipe,
              id_funcao: item.id_funcao,
              id_colaborador: item.id_colaborador,
              id_indicador: this.campanha.id_indicador,
              valor_bonus: this.form.valorBonus,
              qtde_de: this.form.quantidadeDe,
              qtde_ate: this.form.quantidadeAte
            };
            await serviceParametro.postColaboradorCampanhaProgressiva(form);
          })
        );
        // Sucesso: Executado após todas as operações serem concluídas
        this.$notify({
          type: "success",
          text: "Faixas adicionadas"
        });
        this.refreshAdd();
        this.resetForm = true;
      } catch (erro) {
        // Erro: Captura qualquer erro ocorrido em qualquer promessa
        console.error(erro);
        this.$notify({
          type: "error",
          text: "Erro ao adicionar faixas"
        });
      }
    },

    async addFaixa(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_empresa: item.id_empresa,
          id_campanha_equipe: this.campanha.id_campanha_equipe,
          id_funcao: item.id_funcao,
          id_colaborador: item.id_colaborador,
          id_indicador: this.campanha.id_indicador,
          valor_bonus: this.form.valorBonus,
          qtde_de: this.form.quantidadeDe,
          qtde_ate: this.form.quantidadeAte
        };
        await serviceParametro.postColaboradorCampanhaProgressiva(form);
        this.$notify({
          type: "success",
          text: `Faixa adicionada para ${item.colaborador}`
        });

        this.refreshAdd();
      } catch (error) {
        if (error.response.status == 422) {
          this.$notify({
            type: "error",
            text: error.response.data.qtde_de[0]
          });
        } else {
          this.$notify({
            type: "error",
            text: `Erro ao adicionar faixa para ${item.colaborador}`
          });
        }
      }
    },

    async removerTodasFaixas() {
      try {
        await Promise.all(
          this.colaboradoresParticipantesProgressiva.map(async item => {
            await serviceParametro.deleteColaboradorCampanhaProgressiva(
              item.id_campanha_prog_colaborador
            );
          })
        );
        // Sucesso: Executado após todas as remoções serem concluídas
        this.$notify({
          type: "warning",
          text: "Faixas removidas"
        });
        this.refreshRemove();
      } catch (erro) {
        // Erro: Captura qualquer erro ocorrido em qualquer promessa
        console.error(erro);
        this.$notify({
          type: "error",
          text: "Erro ao remover faixas"
        });
      }
    },

    async removeFaixa(item) {
      try {
        await serviceParametro.deleteColaboradorCampanhaProgressiva(
          item.id_campanha_prog_colaborador
        );

        this.$notify({
          type: "warning",
          text: `Faixa removida para ${item.nome}`
        });
        this.refreshRemove();
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remover faixa para ${item.nome}`
        });
      }
    },

    moveAdded(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.addFaixa(colaborador);
      }
    },

    moveRemove(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.removeFaixa(colaborador);
      }
    },

    close() {
      this.resetForm = true;
      this.$emit("close");
    },

    refreshAdd() {
      this.$emit("atualizaColaboradoresParticipantesProgressiva");
    },

    refreshRemove() {
      this.$emit("atualizaColaboradoresParticipantesProgressiva");
    },

    linkedFilter(keyword) {
      if (keyword == "") {
        this.linked = this.colaboradoresParticipantesProgressiva;
      }

      this.linked = this.colaboradoresParticipantesProgressiva.filter(
        colaboradorEquipe =>
          colaboradorEquipe.colaborador
            .toUpperCase()
            .includes(keyword.toUpperCase())
      );
    },

    unLinkedFilter(keyword) {
      if (keyword == "") {
        this.linked = this.colaboradoresNaoParticipantesProgressiva;
      }

      this.unLinked = this.colaboradoresNaoParticipantesProgressiva.filter(
        colaboradorEquipe =>
          colaboradorEquipe.colaborador
            .toUpperCase()
            .includes(keyword.toUpperCase())
      );
    },

    clearableUnLinked() {
      this.searchNotLink = "";
      this.unLinked = this.colaboradoresNaoParticipantesProgressiva;
    },

    clearableLinked() {
      this.searchLink = "";
      this.linked = this.colaboradoresParticipantesProgressiva;
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  max-height: 350px;
  padding: 8px;
  overflow-y: scroll;
}

.draggable-area.include-persons {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-persons {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-colaborador-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
