var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"70%","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"800"}},[_c('v-toolbar',{attrs:{"color":"grey","dark":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-card',{staticClass:"pt-6 overflow-auto",attrs:{"height":"735"}},[_c('BaseTable',{staticClass:"mr-2 ml-2",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"qtde_de","group-by":"colaborador","paginate":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Faixas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{staticClass:"text-start",attrs:{"colspan":"10"}},[_c('v-icon',{attrs:{"color":"blue darken-1","small":""}},[_vm._v("mdi-arrow-right-drop-circle")]),_vm._v(" "+_vm._s(items[0].colaborador)+" ")],1)]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('Segmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}},{key:"item.valor_bonus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_bonus))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }