<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="70%"
    transition="dialog-transition"
  >
    <v-card height="800">
      <v-toolbar color="grey" dark>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('close')" v-bind="attrs" v-on="on" icon>
              <v-icon color="error">mdi mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-toolbar>
      <v-card height="735" class="pt-6 overflow-auto">
        <BaseTable
          :headers="headers"
          :items="items"
          :search="search"
          sort-by="qtde_de"
          group-by="colaborador"
          class="mr-2 ml-2"
          :paginate="true"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Faixas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`group.header`]="{ items }">
            <td colspan="10" class="text-start">
              <v-icon color="blue darken-1" small
                >mdi-arrow-right-drop-circle</v-icon
              >
              {{ items[0].colaborador }}
            </td>
          </template>
          <template v-slot:[`item.id_band`]="{ item }">
            <Segmento :segmento="item.id_band" />
          </template>

          <template v-slot:[`item.indicador`]="{ item }">
            {{ item.id_indicador }} - {{ item.indicador }}
          </template>

          <template v-slot:[`item.valor_bonus`]="{ item }">
            {{ item.valor_bonus | BrazilianCurrency }}
          </template>
        </BaseTable>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import Segmento from "@/components/shared/BaseSegmento";
import BaseTable from "@/components/shared/NewBaseTable";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "ModalColaboradorCampanhaProgressiva",

  components: {
    Segmento,
    BaseTable
  },

  mixins: [currencyMixin],

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Função", value: "cod_funcao", align: "end" },
        { text: "Descricao", value: "funcao" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Indicador", value: "indicador" },
        { text: "De", value: "qtde_de" },
        { text: "Até", value: "qtde_ate" },
        { text: "Bônus", value: "valor_bonus", align: "end" }
      ]
    };
  }
};
</script>
