<template>
  <v-form ref="formFaxiMetaProgressiva">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="form.quantidadeDe"
          label="Quantidade de:"
          type="number"
          :rules="[rules.required, rules.maiorZero]"
          @input="checkForm"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="form.quantidadeAte"
          type="number"
          label="Quantidade até:"
          :rules="[rules.required, rules.maiorZero, rules.maiorQuantidadeDe]"
          @input="checkForm"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <vuetify-money
          v-model.number="form.valorBonus"
          label="Valor Bônus"
          :options="optionsVuetifyMoney"
          @input="checkForm"
        ></vuetify-money>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  name: "FormMetaProgressivaModelo",

  props: {
    resetForm: {
      type: Boolean
    }
  },

  data() {
    return {
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 6,
        precision: 2
      },
      form: {
        quantidadeDe: null,
        quantidadeAte: null,
        valorBonus: null
      },
      rules: {
        required: value => !!value || "Valor obrigatorio",
        maiorZero: value =>
          parseFloat(value) > 0 || "O valor deve ser maior que zero",
        maiorQuantidadeDe: value => {
          return (
            value >= parseFloat(this.form.quantidadeDe) ||
            "O valor deve ser maior ou igual ao inicial"
          );
        }
      }
    };
  },

  watch: {
    form: {
      handler() {
        this.$emit("changeResetForm");
      },
      deep: true
    },

    resetForm(value) {
      if (value) {
        this.form = {
          quantidadeDe: null,
          quantidadeAte: null,
          valorBonus: null
        };
      }
    }
  },

  methods: {
    checkForm() {
      const formIsValid =
        this.$refs.formFaxiMetaProgressiva.validate() &&
        parseFloat(this.form.valorBonus) > 0;
      this.$emit("formIsValid", formIsValid);
      this.$emit("form", { ...this.form });
    }
  }
};
</script>
