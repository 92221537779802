<template>
  <div class="mt-4">
    <!--Tabela Metas Cadastradas-->
    <v-data-table
      :headers="headers"
      :items="listDefinirEquipe(getMetasCadastradas)"
      no-data-text="Nenhuma meta cadastrada"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_campanha_funcao"
      show-expand
      sort-by="cod_funcao"
      class="elevation-1 mb-3"
      :loading="loading"
      loading-text="Loading..."
    >
      <template v-slot:top>
        <v-card-title> Metas Equipes </v-card-title>
      </template>
      <!-- Descrição -->
      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>
      <!-- Descrição -->

      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.perc_cresc`]="{ item }">
        <span v-if="item.perc_cresc">
          <v-icon color="green">mdi-finance</v-icon>
          {{ item.perc_cresc }}%
        </span>
        <span v-else>Não possui</span>
      </template>

      <!-- Prioridade do indicador -->
      <template v-slot:[`item.maior_menor`]="{ item }">
        <span v-if="item.maior_menor === '>'">
          <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
          Maior
        </span>
        <span v-if="item.maior_menor === '<'">
          <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
          Menor
        </span>
      </template>

      <!-- Lista de Empresas Participantes -->
      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn color="primary" small @click="expand(!isExpanded)"
          >expandir</v-btn
        >
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ListaEquipesParticipantes
            :onEdite="onEdite"
            :items="equipesParticipantesFuncao(item)"
          />
        </td>
      </template>

      <!-- Ações -->
      <template v-if="onEdite" v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="vincularEquipe(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
          </template>
          <span>Vincular Equipe<br />a Campanha</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <DraggableEquipeCampanha
      :dialog="dialog"
      @close="dialog = false"
      @change:fecthEquipes="vincularEquipe($event)"
      :dadosCampanha="dados"
      :equipesNaoParticipantes="equipesNaoParticipantes"
      :equipesParticipantes="equipesParticipantes"
    />
  </div>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import DraggableEquipeCampanha from "./DraggableEquipeCampanha";
import ListaEquipesParticipantes from "./ListaEquipesParticipantes";
import serviceCampanha from "@/services/campanha";
import serviceEmpresa from "@/services/empresa";
import { mapActions, mapGetters } from "vuex";
import initMixin from "../../initMixin";
import currencyMixin from "@/mixins/currencyMixin";
import comissao from "@/services/http/comissaoService";
export default {
  name: "TableMetaEquipes",

  components: {
    BaseSegmento,
    ListaEquipesParticipantes,
    DraggableEquipeCampanha
  },

  props: {
    onEdite: {
      type: Boolean,
      default: true
    }
  },

  mixins: [initMixin, currencyMixin],

  data() {
    return {
      expanded: [],
      singleExpand: false,
      loading: false,
      dialog: false,
      dados: {},
      equipesNaoParticipantes: [],
      equipesParticipantes: []
    };
  },

  computed: {
    ...mapGetters({
      getFuncoesPublicoAlvoSelecionado: "campanha/getPublicoAlvoSelecionado",
      getMetasCadastradas: "campanha/getMetasCadastradas",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes",
      getCampanhasEquipes: "campanha/getCampanhasEquipes",
      getEquipes: "empresa/getEquipes"
    }),

    headers() {
      let headers = [
        {
          text: "Cód. Função",
          align: "start",
          value: "cod_funcao"
        },
        {
          text: "Função",
          value: "funcao"
        },
        {
          text: "segmento",
          value: "id_band"
        },
        { text: "Indicador", value: "indicador" },
        { text: "Tipo de Meta", value: "tipo_meta" },
        { text: "Base de Cálculo", value: "tipo_calculo" },
        { text: "Crescimento", value: "perc_cresc" },
        { text: "Prioridade do Indicador", value: "maior_menor" },
        { text: "Meta/Bônus", value: "data-table-expand", align: "center" }
      ];

      if (this.onEdite) {
        headers.splice(headers.length - 1, 0, {
          text: "Vicular Equipes",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  methods: {
    ...mapActions({
      fetchCampanhasEquipes: "campanha/fetchCampanhasEquipes"
    }),
    async getFuncoesCampanha() {
      try {
        this.loading = true;
        await this.initActionFeatMetasCadastradas();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Houve um erro"
        });
      } finally {
        this.loading = false;
      }
    },

    listDefinirEquipe(value) {
      const metasEquipes = value?.filter(item => {
        if (item.id_tipo_meta == 6 || item.id_tipo_meta == 21) return item;
      });
      return metasEquipes;
    },

    async vincularEquipe(item) {
      try {
        this.dados = {
          id_funcao: item.id_funcao,
          id_indicador: item.id_indicador
        };
        let {
          data: { data }
        } = await serviceCampanha.getEmpresasParticipantes({
          id_campanha: this.$route.params.idCampanha
        });

        let idEmpresas = [];

        data.map(item => {
          if (item.id_empresa) {
            idEmpresas.push(item.id_empresa);
          }
        });

        const empresasPerticipantes = idEmpresas.toString();

        const campanhaEquipe = await serviceCampanha.getCampanhaEquipe({
          per_page: -1,
          id_funcao: item.id_funcao,
          id_indicador: item.id_indicador,
          id_campanha: this.$route.params.idCampanha
        });

        const colaboradorEquipe = await comissao()
          .colaboradorEquipe()
          .show({
            per_page: -1,
            id_empresa: empresasPerticipantes,
            id_funcao: item.id_funcao
          });

        let idEquipeColaborador = colaboradorEquipe.data.data.map(
          item => item.id_equipe
        );
        idEquipeColaborador = [...new Set(idEquipeColaborador)].toString();

        const equipe = await serviceEmpresa.getEquipes({
          per_page: -1,
          id_empresa: empresasPerticipantes,
          id_equipe: idEquipeColaborador,
          ativo: "S",
          possui_vendedor: "S"
        });
        this.dialog = true;

        this.equipesParticipantes = campanhaEquipe.data.data;

        const participantes = campanhaEquipe.data.data.map(
          item => item.id_equipe
        );

        this.equipesNaoParticipantes = equipe.data.data.filter(
          item => !participantes.includes(item.id_equipe)
        );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao buscar empresas e equipes"
        });
      } finally {
        this.atualizaListaEquipesCampanha();
      }
    },

    atualizaListaEquipesCampanha() {
      this.fetchCampanhasEquipes({
        id_campanha: this.$route.params.idCampanha,
        per_page: -1
      });
    },

    equipesParticipantesFuncao({ id_funcao, id_indicador, id_tipo_meta }) {
      const equipe = this.getCampanhasEquipes?.filter(item => {
        if (item.id_funcao == id_funcao && item.id_indicador == id_indicador) {
          item.id_tipo_meta = id_tipo_meta;
          return item;
        }
      });
      return equipe;
    }
  },

  mounted() {
    this.getFuncoesCampanha();
    this.atualizaListaEquipesCampanha();
  }
};
</script>

<style></style>
